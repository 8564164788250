<template>
  <div class="commission childpool-wrap">
    <CCard>
      <CCardBody>
        <CRow class="p-1">
          <CCol col="12" xl="5" class="p-1 pl-3 pr-3">
            <CInput label="Partner Id:" horizontal :value="partnerId" disabled />
          </CCol>
          <CCol col="12" xl="7" class="p-1 pl-3 pr-3">
            <CInput label="Partner Name:" horizontal :value="partnerName" disabled />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard class="view-commission-card">
      <CCardBody>
        <div class="font-weight-bold m-1">Commission</div>
        <ValidationObserver ref="form">
          <CForm @submit.prevent="onSubmit">
            <CRow>
              <CCol col="12">
                <CRow
                  class="custom-input"
                  alignVertical="center"
                  v-for="(commission, index) in commissions"
                  :key="index"
                >
                  <CCol col="12" xl="5">
                    <ValidationProvider
                      :name="commission.platform + '\'s commission'"
                      rules="required|max_value:100|min_value:0"
                    >
                      <div slot-scope="{ errors }">
                        <CRow alignVertical="center">
                          <CCol class="text-uppercase" sm="3">{{commission.platform}}</CCol>
                          <CCol sm="9">
                            <currency-input
                              class="input-field"
                              v-model="commission.commission"
                              :precision="3"
                              :currency="{ suffix: '%' }"
                            />
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{errors[0]}}</span>
                            </span>
                          </CCol>
                        </CRow>
                      </div>
                    </ValidationProvider>
                  </CCol>
                  <CCol col="12" xl="7" class="pt-1">
                    <ValidationProvider
                      :name="commission.platform + index"
                      :rules="!commission.id ? 'required' : ''"
                    >
                      <div slot-scope="{ errors }">
                        <CRow alignVertical="center">
                          <CCol class sm="3">Reward Address:</CCol>
                          <CCol sm="9">
                            <input
                              class="input-field text-left"
                              max-length="12"
                              :readonly="!!commission.id"
                              v-model="commission.reward_address"
                            />
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{errors[0]}}</span>
                            </span>
                          </CCol>
                        </CRow>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
              <CCol col="12" class="mt-2 text-right">
                <CButton class="pl-4 pr-4" color="primary" type="submit">Save</CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
    <CCard class="view-history-card">
      <CCardBody>
        <div class="font-weight-bold m-1">History</div>
        <CDataTable
          class="mb-0"
          outlined
          striped
          hover
          :items="commissionHistories"
          :fields="tableHeaders"
          head-color="light"
          no-sorting
        >
          <td class="text-center" slot="order" slot-scope="{ item, index }">{{ index + 1 }}</td>
          <td class="text-center" slot="platform" slot-scope="{ item }">{{ item.platform }}</td>
          <td class="text-center" slot="commission" slot-scope="{ item }">{{ item.commission }}</td>
          <td
            class="text-center"
            slot="updateAt"
            slot-scope="{ item }"
          >{{ item.updated_at | moment("YYYY-MM-DD HH:mm") }}</td>
          <td
            class="text-center"
            slot="updated_by_user_name"
            slot-scope="{ item }"
          >{{ item.updated_by_user_name || '' }}</td>
        </CDataTable>
        <div>
          <CPagination
            v-if="pages>1"
            :activePage.sync="activePage"
            :pages="pages"
            align="end"
            @update:activePage="pushPage"
          />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'Commission',
  data() {
    return {
      tableHeaders: [
        { key: 'order', label: '#', _classes: 'text-center' },
        { key: 'platform', label: 'Platform', _classes: 'text-center' },
        { key: 'commission', label: 'Commission', _classes: 'text-center' },
        { key: 'updateAt', label: 'Update at', _classes: 'text-center' },
        { key: 'updated_by_user_name', label: 'Update by', _classes: 'text-center' },
      ],
      commissionHistories: [],
      partnerId: '',
      partnerName: '',
      commissions: [],
      limit: 5,
      pages: 0,
      activePage: 1,
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
  },
  async mounted() {
    this.partnerId = this.$route.params.id;
    this.partnerName = await this.getCurrentPatner();

    this.getCommissions();
    this.getCommissionHistories();
  },
  methods: {
    async getCommissions() {
      try {
        const params = {
          limit: 1000000,
          offset: this.offset,
        };
        const result = await this.$http.get(endpoints.getCommissions(this.partnerId), { params: params });

        this.commissions = result.data ? result.data.items : [];
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });

        return null;
      }
    },

    async getCurrentPatner() {
      try {
        const resultP = await this.$http.get(endpoints.getGrandchild(this.partnerId));
        return resultP.data.name;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });

        return null;
      }
    },

    async getCommissionHistories() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
        };
        const result = await this.$http.get(endpoints.getCommissionHistory(this.partnerId), { params: params });

        if (result.data) {
          this.commissionHistories = result.data.items.slice();
          this.pages = Math.ceil(result.data.total / this.limit);
        }
      } catch (err) {
        return null;
      }
    },

    async pushPage() {
      const commissionHistories = await this.getCommissionHistories();
    },

    async onSubmit() {
      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        this.updateCommisions();
      });
    },

    async updateCommisions() {
      try {
        const items = this.commissions.map(item => {
          // Update
          if (item.id) {
            return {
              id: item.id,
              platform: item.platform,
              commission: item.commission,
              reward_address: item.reward_address,
              staking_platform_id: item.staking_platform_id,
            };
          }

          return {
            platform: item.platform,
            commission: item.commission,
            reward_address: item.reward_address,
            staking_platform_id: item.staking_platform_id,
          };
        });

        const result = await this.$http.post(endpoints.updateCommissions(this.partnerId), { items: items });
        const updateCommissions = (result.data ? result.data : []) || [];
        this.commissions = updateCommissions.slice();

        // Reload commission histories
        this.activePage = 1;
        this.getCommissionHistories();

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: 'Commissions has been updated successful.',
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.commission /deep/ {
  .view-history-card,
  .view-commission-card {
    .error-msg {
      color: red;
    }

    .form-control {
      text-align: right;
    }
    .form-group {
      margin-bottom: 0;
    }
    .custom-input {
      padding-top: 4px;
      .input-field {
        padding: 6px 12px;
        width: 100%;
        border: 1px solid #d8dbe0;
        border-radius: 0.25rem;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
